import React from 'react';

import { fg } from '@confluence/feature-gating';

import { ShareAndRestrictButtonPlaceholder as ShareAndRestrictButtonPlaceholderV1 } from './v1/shareAndRestrictButton/ShareAndRestrictButtonPlaceholder';
import { ShareAndRestrictButtonPlaceholder as ShareAndRestrictButtonPlaceholderV2 } from './v2/shareAndRestrictButton/ShareAndRestrictButtonPlaceholder';

export const PlaceholderButtonVersionDecider = () => {
	return fg('confluence_frontend_unified_restrict_and_share_v2') ? (
		<ShareAndRestrictButtonPlaceholderV2 />
	) : (
		<ShareAndRestrictButtonPlaceholderV1 />
	);
};
