import type {
	GeneralAccessLevel,
	KnownErrors,
	Principal,
	PrincipalAndRole,
	Role,
} from '../../controlled/commonTypes';
import { type ContentRestrictionsQueryData } from '../Controller';

import type {
	ShareAndRestrictDialogQueryV1_content_nodes_restrictions,
	ShareAndRestrictDialogQueryV1_content_nodes_restrictions_read,
	ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update,
	ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update_restrictions_userWithRestrictions,
	ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update_restrictions_group,
	ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update_restrictions_userWithRestrictions_nodes,
} from './__types__/ShareAndRestrictDialogQueryV1';

type UsersAndGroups = {
	users: Principal[];
	groups: Principal[];
};

type ReadAndUpdate = {
	read: UsersAndGroups;
	update: UsersAndGroups;
};

/**
 * Converts the raw query data to the internal representation for content restrictions
 */
export function getContentRestrictions(
	restrictions: ShareAndRestrictDialogQueryV1_content_nodes_restrictions,
): ContentRestrictionsQueryData | null {
	const readAndUpdateLists = convertRestrictionsObject(restrictions);
	return {
		generalAccessLevel: getGeneralAccessLevel(readAndUpdateLists),
		principals: getPrincipalsAndRoles(readAndUpdateLists),
	};
}

/**
 * Converts the 'read' and 'update' structures from the query data into their
 * structural equivalents but using our own Principal
 */
function convertRestrictionsObject(
	source: ShareAndRestrictDialogQueryV1_content_nodes_restrictions,
): ReadAndUpdate {
	const ret: ReadAndUpdate = {
		read: { users: [], groups: [] },
		update: { users: [], groups: [] },
	};

	const { read, update } = source;

	if (read) convertUsersAndGroups(read, ret.read);
	if (update) convertUsersAndGroups(update, ret.update);

	return ret;
}

/**
 * Iterates through the users and groups nodes of query data, converting
 * entries into Principal format, and pushing them to accumulators
 */
function convertUsersAndGroups(
	source:
		| ShareAndRestrictDialogQueryV1_content_nodes_restrictions_read
		| ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update,
	{ users, groups }: UsersAndGroups,
) {
	const { restrictions } = source;
	if (restrictions) {
		const { userWithRestrictions, group } = restrictions;

		if (userWithRestrictions) pushUserNodes(userWithRestrictions, users);
		if (group) pushGroupNodes(group, groups);
	}
}

/**
 * Determines the General Access level, based on are there any listed viewers,
 * and are there any listed editors
 */
export function getGeneralAccessLevel({
	read: { groups: readGroups, users: readUsers },
	update: { groups: updateGroups, users: updateUsers },
}: ReadAndUpdate): GeneralAccessLevel {
	const hasViewPrincipals = readGroups.length + readUsers.length > 0;
	const hasEditPrincipals = updateGroups.length + updateUsers.length > 0;

	if (hasViewPrincipals) return 'restricted';
	if (hasEditPrincipals) return 'open-can-view';
	return 'open-can-edit';
}

/**
 * Coalesce the read and update lists into a single, sorted list, with each principal
 * appearing only once, alongside their intended role
 */
export function getPrincipalsAndRoles({ read, update }: ReadAndUpdate): PrincipalAndRole[] {
	const map = new Map<string, PrincipalAndRole>();

	const addToMapWithRole = (principals: Principal[], role: Role) =>
		principals.forEach((principal) => map.set(principal.id, { role, principal }));

	// Initially, populate the map with viewers
	addToMapWithRole([...read.users, ...read.groups], 'can-view');

	// Do another pass for the editors, potentially overwriting entries set in the
	// first pass, with their edit role
	addToMapWithRole([...update.users, ...update.groups], 'can-edit');

	// Convert the map to a PrincipalAndRole array, and sort by name
	return Array.from(map)
		.map(([, principalAndRole]) => principalAndRole)
		.sort(
			(
				{ principal: { name: a } }: PrincipalAndRole,
				{ principal: { name: b } }: PrincipalAndRole,
			) => {
				if (a === b) return 0;
				else if (a > b) return 1;
				return -1;
			},
		);
}

/**
 * Converts groups from query format to Principal format, and pushes them to an accumulator
 */
function pushGroupNodes(
	source: ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update_restrictions_group,
	dest: Principal[],
) {
	const { nodes } = source;
	if (nodes) {
		for (const node of nodes) {
			if (!node) continue;
			dest.push({
				type: 'group',
				id: node.id || '',
				name: node.name || '',
			});
		}
	}
}

/**
 * Converts users from query format to Principal format, and pushes them to an accumulator
 */
function pushUserNodes(
	source: ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update_restrictions_userWithRestrictions,
	dest: Principal[],
) {
	const { nodes } = source;
	if (nodes) {
		for (const node of nodes) {
			if (!node) continue;

			/**
			 * TODO: there are some fields we were querying for in the Restrictions
			 * dialog, but not using here (yet):
			 * • type
			 */

			dest.push({
				type: 'user',
				id: node.accountId || '',
				name: node.displayName || '',
				avatarUrl: node.profilePicture?.path || '', // TODO
				email: node.email || '',
				knownErrors: getKnownErrors(node),
				sitePermissionType: node.permissionType || '',
			});
		}
	}
}

/**
 * Retrieves known errors based on the given node's restrictions.
 *
 * @param {ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update_restrictions_userWithRestrictions_nodes} node - The node containing restriction details.
 * @returns {KnownErrors} An object containing access warnings and parent link details, or undefined if no errors are found.
 */
export function getKnownErrors(
	node: Pick<
		ShareAndRestrictDialogQueryV1_content_nodes_restrictions_update_restrictions_userWithRestrictions_nodes,
		'hasSpaceEditPermission' | 'hasSpaceViewPermission' | 'restrictingContent'
	>,
): KnownErrors {
	const { restrictingContent, hasSpaceEditPermission, hasSpaceViewPermission } = node;

	if (hasSpaceViewPermission === false) {
		return { accessWarning: 'space-view-access-warning', parentLink: null };
	}
	if (restrictingContent) {
		return {
			accessWarning: 'parent-access-warning',
			parentLink: {
				webui: restrictingContent.links?.webui,
				base: restrictingContent.links?.base,
			},
		};
	}
	// TODO: Implement a check for the principal's current access level.
	// If the access level is 'view', it's not relevant.
	// If the access level is 'edit', it is relevant.
	if (hasSpaceEditPermission === false) {
		return { accessWarning: 'space-edit-access-warning', parentLink: null };
	}
	return undefined;
}
