import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { fg } from '@confluence/feature-gating';

import { PlaceholderButtonVersionDecider } from './PlaceholderButtonVersionDecider';
import {
	type ShareAndRestrictButtonProps as ShareAndRestrictButtonPropsV1,
	type ContentType as ContentTypeV1,
	type ContentSubType as ContentSubTypeV1,
} from './v1/shareAndRestrictButton/ShareAndRestrictButton';
import {
	type ShareAndRestrictButtonProps as ShareAndRestrictButtonPropsV2,
	type ContentType as ContentTypeV2,
	type ContentSubType as ContentSubTypeV2,
} from './v2/shareAndRestrictButton/ShareAndRestrictButton';
import {
	getClosestAncestorWithViewRestrictions as getClosestAncestorWithViewRestrictionsV1,
	getClosestAncestorWithViewRestrictions as getClosestAncestorWithViewRestrictionsV2,
} from './v1/controller/queries/dataToQueriesState';
import { type ShareAndRestrictDialogQueryV1_content_nodes_ancestors as ShareAndRestrictDialogQuery_content_nodes_ancestorsV1 } from './v1/controller/queries/__types__/ShareAndRestrictDialogQueryV1';
import { type ShareAndRestrictDialogQuery_content_nodes_ancestors as ShareAndRestrictDialogQuery_content_nodes_ancestorsV2 } from './v2/controller/queries/__types__/ShareAndRestrictDialogQuery';
import { SHARE_BUTTON_PAGE_SEGMENT_LOAD } from './perf.config';

const ShareAndRestrictButtonLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShareAndRestrictButton" */ './ButtonVersionDecider'
			)
		).ButtonVersionDecider,
	loading: PlaceholderButtonVersionDecider,
});

export type ShareAndRestrictButtonProps =
	| ShareAndRestrictButtonPropsV2
	| ShareAndRestrictButtonPropsV1;

export const ShareAndRestrictButton = (props: Omit<ShareAndRestrictButtonProps, 'flags'>) => (
	<>
		<PageSegmentLoadStart
			metric={SHARE_BUTTON_PAGE_SEGMENT_LOAD}
			isCustomStart
			key={props.contentId}
		/>
		<ShareAndRestrictButtonLoader {...props} />
	</>
);

type ContentType = ContentTypeV2 | ContentTypeV1;
type ContentSubType = ContentSubTypeV2 | ContentSubTypeV1;

export type { ContentType, ContentSubType };

const getClosestAncestorWithViewRestrictions = (
	ancestors:
		| (
				| ShareAndRestrictDialogQuery_content_nodes_ancestorsV1
				| ShareAndRestrictDialogQuery_content_nodes_ancestorsV2
				| null
		  )[]
		| null,
): string | null | undefined => {
	return fg('confluence_frontend_unified_restrict_and_share_v2')
		? getClosestAncestorWithViewRestrictionsV2(
				ancestors as (ShareAndRestrictDialogQuery_content_nodes_ancestorsV2 | null)[] | null,
			)
		: getClosestAncestorWithViewRestrictionsV1(
				ancestors as (ShareAndRestrictDialogQuery_content_nodes_ancestorsV1 | null)[] | null,
			);
};
export { getClosestAncestorWithViewRestrictions };
